import Input from "@ui/Input.tsx";
import Select, { SelectValueType } from "@ui/select";
import { Dispatch, SetStateAction, useMemo } from "react";

import HeaderContainer from "../../../components/HeaderContainer.tsx";
import useCountries from "../../../hooks/useCountries.tsx";
import useManageWalletChannels from "../../../hooks/useManageWalletChannels.ts";

export interface FilterValueProps {
    reference: string;
    identifier: string;
    paymentMethods: SelectValueType;
}

interface Props {
    filter: FilterValueProps;
    setFilter: Dispatch<SetStateAction<FilterValueProps>>;
}

const FilterTransferParameter = (props: Props) => {
    const { filter, setFilter } = props;
    const { payinWallets } = useManageWalletChannels();
    const { getCountryByCountryId } = useCountries();

    const wallets = useMemo(() => {
        return payinWallets.map(item => {
            const country = getCountryByCountryId(item.country_id);

            const countryName = country?.name
                ? ` (${country?.name || "---"})`
                : "";
            return {
                ...item,
                value: item.id.toString(),
                label: `${item.name}${countryName}`
            };
        });
    }, [getCountryByCountryId, payinWallets]);

    return (
        <HeaderContainer>
            <h4 className="mb-4">Filtres</h4>

            <div className="grid grid-cols-12 gap-5">
                <Select
                    containerClassName="col-span-4"
                    placeholder="Moyen de paiement d'émission"
                    value={filter.paymentMethods}
                    onChange={value =>
                        setFilter({ ...filter, paymentMethods: value })
                    }
                    options={wallets}
                />

                <Input
                    type="text"
                    className="col-span-4"
                    inputClassName="placeholder-gray-400 text-gray-600"
                    placeholder="Référence moyen de paiement émission"
                    value={filter.reference}
                    onChange={e =>
                        setFilter({
                            ...filter,
                            reference: e.target.value
                        })
                    }
                />

                <Input
                    type="text"
                    className="col-span-4"
                    inputClassName="placeholder-gray-400 text-gray-600"
                    placeholder="ID Mydunya"
                    value={filter.identifier}
                    onChange={e =>
                        setFilter({
                            ...filter,
                            identifier: e.target.value
                        })
                    }
                />
            </div>
        </HeaderContainer>
    );
};

export default FilterTransferParameter;
