import Button from "@ui/Button.tsx";
import Input from "@ui/Input.tsx";
import PaymentMethodSelect from "@ui/payment-method-select";
import Select, { SelectValueType } from "@ui/select";
import { Dispatch, SetStateAction } from "react";

import HeaderContainer from "../../../components/HeaderContainer.tsx";
import { currentDateForInput } from "../../../helpers";
import { TRANSACTION_STATUS } from "../../../types";

const STATUS = [
    {
        label: "Succès",
        value: TRANSACTION_STATUS.SUCCESS
    },
    {
        label: "En attente",
        value: TRANSACTION_STATUS.PENDING
    },
    {
        label: "Echec",
        value: TRANSACTION_STATUS.FAILED
    },
    {
        label: "Remboursé",
        value: TRANSACTION_STATUS.REFUND
    },
    {
        label: "A remboursé",
        value: TRANSACTION_STATUS.TO_REFUND
    },
    {
        label: "Tout",
        value: ""
    }
];

const SERVICES = [
    {
        label: "Transfert",
        value: "transfer"
    }
    /*{
        label: "Cotisation",
        value: "membership"
    }*/
];

export interface FilterValueType {
    date: string;
    status: SelectValueType;
    idTransfer: string;
    paymentMethods: {
        pay_in: number[];
        pay_out: number[];
    };
    service: SelectValueType;
    idAccount: string;
    payInPartner: string;
    payOutPartner: string;
}

interface Props {
    onFilter: () => void;
    loading?: boolean;
    filter: FilterValueType;
    setFilter: Dispatch<SetStateAction<FilterValueType>>;
}

const FilterTransfersParams = (props: Props) => {
    const { onFilter, loading, filter, setFilter } = props;

    return (
        <HeaderContainer>
            <div className="flex items-center space-x-5 mb-3">
                <h4>Filtres</h4>

                <Button withAuto size="sm" onClick={onFilter} loading={loading}>
                    Appliquer
                </Button>
            </div>

            <div className="grid grid-cols-6 gap-5">
                <Input
                    type="date"
                    inputClassName="placeholder-gray-400 text-gray-600"
                    value={filter.date}
                    onChange={e =>
                        setFilter({
                            ...filter,
                            date: e.target.value
                        })
                    }
                    max={currentDateForInput()}
                />

                <Select
                    isSearchable={false}
                    placeholder="Status trx"
                    value={filter.status}
                    onChange={value => setFilter({ ...filter, status: value })}
                    options={STATUS}
                />

                <Input
                    type="text"
                    inputClassName="placeholder-gray-400 text-gray-600"
                    placeholder="ID transaction"
                    value={filter.idTransfer}
                    onChange={e =>
                        setFilter({ ...filter, idTransfer: e.target.value })
                    }
                />

                <PaymentMethodSelect
                    menuWithAuto
                    onChange={value =>
                        setFilter({ ...filter, paymentMethods: value })
                    }
                />

                <Select
                    isSearchable={false}
                    placeholder="Service"
                    disable
                    value={filter.service}
                    onChange={value => setFilter({ ...filter, service: value })}
                    options={SERVICES}
                />

                <Input
                    value={filter.idAccount}
                    onChange={e =>
                        setFilter({ ...filter, idAccount: e.target.value })
                    }
                    type="text"
                    placeholder="ID Compte"
                    inputClassName="placeholder-gray-400 text-gray-600"
                />

                <Input
                    type="text"
                    inputClassName="placeholder-gray-400 text-gray-600"
                    placeholder="Ref Pay In partner"
                    value={filter.payInPartner}
                    onChange={e =>
                        setFilter({ ...filter, payInPartner: e.target.value })
                    }
                />

                <Input
                    type="text"
                    inputClassName="placeholder-gray-400 text-gray-600"
                    placeholder="Ref Pay Out partner"
                    value={filter.payOutPartner}
                    onChange={e =>
                        setFilter({ ...filter, payOutPartner: e.target.value })
                    }
                />
            </div>
        </HeaderContainer>
    );
};

export default FilterTransfersParams;
