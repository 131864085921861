import Button from "@ui/Button.tsx";
import Modal from "@ui/modal";
import Textarea from "@ui/Textarea.tsx";
import { ChangeEvent } from "react";

import { ModalPropsType } from "../../../types";

interface Props extends ModalPropsType {
    onConfirm: () => void;
    loading?: boolean;
    reason: string;
    onReasonChange: (value: ChangeEvent<HTMLTextAreaElement>) => void;
    error?: string;
}

const ReasonSuspendModal = (props: Props) => {
    const {
        isOpen,
        closeModal,
        onConfirm,
        loading,
        error = "",
        reason,
        onReasonChange
    } = props;

    return (
        <Modal isOpen={isOpen} closeModal={closeModal} className="w-[500px]">
            <div className="p-6">
                <h3 className="text-center text-3xl text-gray-700 mb-3">
                    Suspenssion
                </h3>

                <Textarea
                    className="mb-5"
                    placeholder="Reason de la suspenssion"
                    value={reason}
                    onChange={onReasonChange}
                    error={error}
                />

                <div className="flex items-center justify-center space-x-4">
                    <Button variant="gray" withAuto onClick={closeModal}>
                        Annuler
                    </Button>

                    <Button withAuto onClick={onConfirm} loading={loading}>
                        Confirmer
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ReasonSuspendModal;
