import Input from "@ui/Input.tsx";
import Select, { SelectValueType } from "@ui/select";
import { ChangeEvent, useCallback, useMemo } from "react";

import HeaderContainer from "../../../components/HeaderContainer.tsx";
import { DATATABLE, STATUS_ACCOUNT, STATUS_KYC } from "../../../constants";
import useCountries from "../../../hooks/useCountries.tsx";
import { FilterKycParamsType } from "../../../types";

interface Props {
    filterKycParams: FilterKycParamsType;
    updateKycFilter: (value: FilterKycParamsType) => void;
}

const CARD_FILTER = [
    {
        value: "card_kyc",
        label: "Carte"
    },
    {
        value: "transfer_kyc",
        label: "Sans carte"
    },
    {
        value: "transfer_and_card_kyc",
        label: "Tout"
    }
];

const CEILING = [
    {
        label: "Tout",
        value: "all"
    },
    {
        label: "Presque",
        value: "almost"
    },
    {
        label: "Atteint",
        value: "achieved"
    },
    {
        label: "Non Atteint",
        value: "not-achieved"
    },
    {
        label: "Déplafonné",
        value: "uncapped"
    }
];

const HeaderRisk = (props: Props) => {
    const { filterKycParams, updateKycFilter } = props;
    const { countries } = useCountries();

    const countriesOption = useMemo(() => {
        return countries.map(item => ({
            ...item,
            value: item.phone_code,
            label: item.name
        }));
    }, [countries]);

    const listStatusAccounts = useMemo(() => {
        return Object.entries(STATUS_ACCOUNT).map(([key, value]) => ({
            value: key,
            label: value
        }));
    }, []);

    const listStatusKyc = useMemo(() => {
        return Object.entries(STATUS_KYC).map(([key, value]) => ({
            value: key,
            label: value
        }));
    }, []);

    const handleFilterChange = useCallback(
        (
            value: SelectValueType | string,
            key: keyof typeof filterKycParams
        ) => {
            updateKycFilter({
                [key]: value,
                pageIndex: 0,
                pageSize: DATATABLE.PAGE_SIZE
            });
        },
        [updateKycFilter]
    );

    const handleSelectFilterChange = useCallback(
        (key: keyof typeof filterKycParams) => {
            return (value: SelectValueType) => {
                handleFilterChange(value, key);
            };
        },
        [handleFilterChange]
    );

    const handleInputFilterChange = useCallback(
        (key: keyof typeof filterKycParams) => {
            return (e: ChangeEvent<HTMLInputElement>) => {
                handleFilterChange(e.target.value, key);
            };
        },
        [handleFilterChange]
    );

    return (
        <HeaderContainer>
            <h4 className="mb-4 font-medium">Filtres</h4>

            <div className="grid grid-cols-6 gap-5">
                <Select
                    isSearchable={false}
                    placeholder="Statut compte"
                    options={listStatusAccounts}
                    value={filterKycParams.statusAccount || null}
                    onChange={handleSelectFilterChange("statusAccount")}
                />

                <div className="relative">
                    <label
                        htmlFor="createdDate"
                        className="absolute text-xs -mt-4"
                    >
                        Date de création
                    </label>
                    <Input
                        id="createdDate"
                        type="date"
                        inputClassName="placeholder-gray-400 text-gray-600"
                        placeholder="Date de création"
                        value={filterKycParams.createdDate || ""}
                        onChange={handleInputFilterChange("createdDate")}
                    />
                </div>

                <div className="relative">
                    <label
                        htmlFor="validationKycDate"
                        className="absolute text-xs -mt-4"
                    >
                        Date validation KYC Transfert
                    </label>
                    <Input
                        id="validationTransferKyc"
                        type="date"
                        inputClassName="placeholder-gray-400 text-gray-600"
                        placeholder="Date de validation du KYC Transfert"
                        value={filterKycParams.transferKycValidationDate || ""}
                        onChange={handleInputFilterChange(
                            "transferKycValidationDate"
                        )}
                    />
                </div>

                <div className="relative">
                    <label
                        htmlFor="validationKycDate"
                        className="absolute text-xs -mt-4"
                    >
                        Date validation KYC Carte
                    </label>
                    <Input
                        id="validationTransferKyc"
                        type="date"
                        inputClassName="placeholder-gray-400 text-gray-600"
                        placeholder="Date validation KYC Carte"
                        value={filterKycParams.cardKycValidationDate || ""}
                        onChange={handleInputFilterChange(
                            "cardKycValidationDate"
                        )}
                    />
                </div>

                <Select
                    isSearchable={false}
                    placeholder="Pays"
                    options={countriesOption}
                    value={filterKycParams.country || null}
                    onChange={handleSelectFilterChange("country")}
                />

                <Select
                    isSearchable={false}
                    placeholder="Carte,Sans carte, Tout"
                    options={CARD_FILTER}
                    value={filterKycParams.cardFilter || null}
                    onChange={handleSelectFilterChange("cardFilter")}
                />

                {/*<Select
                    isSearchable={false}
                    placeholder="Statut KYC"
                    options={listStatusKyc}
                    value={filterKycParams.statusKyc || null}
                    onChange={handleSelectFilterChange("statusKyc")}
                />*/}

                <Select
                    isSearchable={false}
                    placeholder="Statut KYC Transfert"
                    options={listStatusKyc}
                    value={filterKycParams.statusKycTransfer || null}
                    onChange={handleSelectFilterChange("statusKycTransfer")}
                />

                <Select
                    isSearchable={false}
                    placeholder="Statut KYC Carte"
                    options={listStatusKyc}
                    value={filterKycParams.statusKycCard || null}
                    onChange={handleSelectFilterChange("statusKycCard")}
                />

                <Select
                    isSearchable={false}
                    placeholder="Plafond"
                    options={CEILING}
                    value={filterKycParams.ceiling || null}
                    onChange={handleSelectFilterChange("ceiling")}
                />
            </div>
        </HeaderContainer>
    );
};

export default HeaderRisk;
