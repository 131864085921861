import HeEyeIcon from "@icon/HeEyeIcon.tsx";
import TbDownloadIcon from "@icon/TbDownloadIcon.tsx";
import { PaginationState } from "@tanstack/react-table";
import { createColumnHelper, Updater } from "@tanstack/table-core";
import Button from "@ui/Button.tsx";
import Checkbox from "@ui/Checkbox.tsx";
import Datatable from "@ui/datatable";
import Tab, { TabButton, TabItem } from "@ui/tab/index.tsx";
import Tooltip from "@ui/Tooltip.tsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";

import { AppLayout } from "../../components/layouts";
import {
    DATATABLE,
    ENDPOINTS,
    PAGES,
    PERMISSIONS,
    SECOND_DATE_TIME_FR_FORMAT
} from "../../constants";
import {
    downloadFile,
    formatStatusAccount,
    formatStatusKyc,
    updateAxiosBaseUrl,
    userHavePermissions
} from "../../helpers";
import useCheckboxDatatable from "../../hooks/useCheckboxDatatable.ts";
import useExportKyc from "../../hooks/useExportKyc.ts";
import useManageKyc from "../../hooks/useManageKyc.tsx";
import { formatDate } from "../../libs/date.ts";
import { cn } from "../../libs/style.ts";
import useToast from "../../libs/useToast.tsx";
import { ApiService, KycType } from "../../types";

import HeaderRisk from "./components/HeaderRisk";

const columnHelper = createColumnHelper<KycType>();

const Risk = () => {
    const [downloadLoading, setDownloadLoading] = useState(false);

    const { exportToPdf } = useExportKyc();
    const [searchParams] = useSearchParams();
    const { customSuccess: customSuccessToast, customError: customErrorToast } =
        useToast();
    const {
        kycListData,
        loading,
        refreshKycList,
        updateKycFilter,
        filterKycParams
    } = useManageKyc();

    const {
        checkData,
        checkAll,
        handleCheckAll,
        handleCheckItem,
        printItemCheckboxStatus,
        resetCheckData
    } = useCheckboxDatatable({ totalData: kycListData.total });

    const navigate = useNavigate();

    const handleSearchChange = useCallback(
        (value: string) => {
            updateKycFilter({ searchValue: value, pageIndex: 0 });
            resetCheckData();
        },
        [resetCheckData, updateKycFilter]
    );

    const paginateData = useMemo(() => {
        return {
            pageIndex: filterKycParams.pageIndex || 0,
            pageSize: filterKycParams.pageSize || DATATABLE.PAGE_SIZE
        };
    }, [filterKycParams.pageIndex, filterKycParams.pageSize]);

    const handlePaginateChange = useCallback(
        (updater: Updater<PaginationState>) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return updateKycFilter(updater(paginateData));
        },
        [paginateData, updateKycFilter]
    );

    useEffect(() => {
        const kycId = searchParams.get("kycId");
        if (kycId) {
            navigate(PAGES.RISK_DETAILS.replace(":id", kycId));
        }
    }, [navigate, searchParams]);

    useEffect(() => {
        refreshKycList();
    }, [refreshKycList]);

    const downloadAccount = useCallback(() => {
        const sendData = {
            ...checkData,
            filter: {
                status: filterKycParams.statusAccount?.value || "",
                created_at: filterKycParams.createdDate,
                transfer_kyc_validated_at:
                    filterKycParams.transferKycValidationDate,
                card_kyc_validated_at: filterKycParams.cardKycValidationDate,
                indicative: filterKycParams.country?.value || "",
                kyc_type: filterKycParams.cardFilter?.value || "",
                status_kyc: filterKycParams.statusKyc?.value || "",
                transfer_kyc_status:
                    filterKycParams.statusKycTransfer?.value || "",
                card_kyc_status: filterKycParams.statusKycCard?.value || "",
                transactional_limit: filterKycParams.ceiling?.value || "",
                search: filterKycParams.searchValue
            }
        };
        setDownloadLoading(true);
        updateAxiosBaseUrl(ApiService.moc);
        window.axios
            .post(ENDPOINTS.DOWNLOAD_KYC, sendData)
            .then(response => {
                const url = response.data.data.url;
                const message = response.data?.message || "";
                if (url && typeof url === "string") {
                    downloadFile(url);

                    setTimeout(() => {
                        customSuccessToast({
                            message: "Succès du téléchargement"
                        });
                    }, 1000);
                } else {
                    customSuccessToast({
                        message
                    });
                }
            })
            .catch(() => {
                customErrorToast({ message: "Echec du téléchargement" });
            })
            .finally(() => setDownloadLoading(false));
    }, [
        checkData,
        customErrorToast,
        customSuccessToast,
        filterKycParams.cardFilter?.value,
        filterKycParams.cardKycValidationDate,
        filterKycParams.ceiling,
        filterKycParams.country,
        filterKycParams.createdDate,
        filterKycParams.searchValue,
        filterKycParams.statusAccount,
        filterKycParams.statusKyc,
        filterKycParams.statusKycCard,
        filterKycParams.statusKycTransfer,
        filterKycParams.transferKycValidationDate
    ]);

    const columns = useMemo(() => {
        return [
            columnHelper.accessor(row => row.created_at, {
                id: "created_at",
                header: () => (
                    <div className="flex items-center space-x-3">
                        <Checkbox
                            id="check-all"
                            checked={checkAll}
                            onChange={handleCheckAll}
                        />

                        <span>Date</span>
                    </div>
                ),
                cell: info => (
                    <div className="flex items-center space-x-3">
                        <Checkbox
                            checked={printItemCheckboxStatus(
                                parseInt(info.row.original.id)
                            )}
                            onChange={e =>
                                handleCheckItem(
                                    e,
                                    parseInt(info.row.original.id)
                                )
                            }
                        />

                        <span>
                            {info.getValue()
                                ? formatDate(
                                      info.getValue() || "",
                                      SECOND_DATE_TIME_FR_FORMAT.replaceAll(
                                          "-",
                                          " "
                                      )
                                  )
                                : "---"}
                        </span>
                    </div>
                ),
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.user, {
                id: "user",
                header: () => <>Nom</>,
                cell: info => <>{info.getValue()?.full_name || "---"}</>,
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.status, {
                id: "status",
                header: () => <>Status compte</>,
                cell: info => <>{formatStatusAccount(info.getValue())}</>,
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.user, {
                id: "user",
                header: () => <>Téléphone</>,
                cell: info => (
                    <>{info.getValue()?.phone_with_indicative || "---"}</>
                ),
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.transfer_kyc_status, {
                id: "transfer_kyc_status",
                header: () => <>Status kyc Transfert</>,
                cell: info => <>{formatStatusKyc(info.getValue() || "")}</>,
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.card_kyc_status, {
                id: "card_kyc_status",
                header: () => <>Status kyc carte</>,
                cell: info => <>{formatStatusKyc(info.getValue() || "")}</>,
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.account_number, {
                id: "account_number",
                header: () => <>Id compte</>,
                cell: info => <>{info.getValue() || "---"}</>,
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.updated_at, {
                id: "updated_at",
                header: () => <>Dernière modification</>,
                cell: info => (
                    <>
                        {info.getValue()
                            ? formatDate(
                                  info.getValue() || "",
                                  SECOND_DATE_TIME_FR_FORMAT.replaceAll(
                                      "-",
                                      " "
                                  )
                              )
                            : "---"}
                    </>
                ),
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.id, {
                id: "id",
                header: () => <>Actions</>,
                cell: info => (
                    <>
                        <div className="flex items-center space-x-4">
                            {userHavePermissions([PERMISSIONS.SHOW_KYC]) && (
                                <Tooltip content="Détail">
                                    <NavLink
                                        className="focus:outline-none text-gray-400 hover:text-gray-500"
                                        to={PAGES.RISK_DETAILS.replace(
                                            ":id",
                                            info.getValue()
                                        )}
                                    >
                                        <HeEyeIcon />
                                    </NavLink>
                                </Tooltip>
                            )}

                            <Tooltip content="Télécharger">
                                <button
                                    onClick={() =>
                                        exportToPdf(info.row.original)
                                    }
                                    className="focus:outline-none text-gray-400 hover:text-gray-500"
                                >
                                    <TbDownloadIcon />
                                </button>
                            </Tooltip>
                        </div>
                    </>
                ),
                footer: info => info.column.id
            })
        ];
    }, [
        checkAll,
        exportToPdf,
        handleCheckAll,
        handleCheckItem,
        printItemCheckboxStatus
    ]);

    return (
        <AppLayout className="space-y-5">
            <Tab activeIndex={1}>
                <TabItem index={1}>
                    <HeaderRisk
                        filterKycParams={filterKycParams}
                        updateKycFilter={data => {
                            updateKycFilter(data);
                            resetCheckData();
                        }}
                    />
                </TabItem>

                <div className="bg-white border rounded-md px-4 py-5">
                    <div className="flex items-center mb-8">
                        <TabButton
                            as="button"
                            index={1}
                            className={isActive =>
                                cn({
                                    "px-4 py-2 text-lg font-medium": true,
                                    "border-b-4 border-transparent": true,
                                    "border-gray-600": isActive
                                })
                            }
                        >
                            List des KYCs
                        </TabButton>

                        <TabButton
                            as="button"
                            index={2}
                            className={isActive =>
                                cn({
                                    "px-4 py-2 text-lg font-medium": true,
                                    "border-b-4 border-transparent": true,
                                    "border-gray-600": isActive
                                })
                            }
                        >
                            Suivi des KPIs
                        </TabButton>

                        <TabButton
                            as="button"
                            index={3}
                            className={isActive =>
                                cn({
                                    "px-4 py-2 text-lg font-medium": true,
                                    "border-b-4 border-transparent": true,
                                    "border-gray-600": isActive
                                })
                            }
                        >
                            List des clients
                        </TabButton>
                    </div>

                    <TabItem index={1}>
                        <Datatable
                            title={
                                <>
                                    <span>Liste des KYC</span>

                                    <Button
                                        withAuto
                                        size="sm"
                                        className="px-3"
                                        onClick={downloadAccount}
                                        loading={downloadLoading}
                                        disabled={
                                            !checkData.uncheck.length &&
                                            !checkData.check.length &&
                                            !checkAll
                                        }
                                    >
                                        Télécharger
                                    </Button>
                                </>
                            }
                            columns={columns}
                            data={kycListData.currentPageData}
                            allDataTotal={kycListData.total}
                            manualFetchData
                            manualPaginateState={{
                                value: {
                                    pageIndex: paginateData.pageIndex,
                                    pageSize: paginateData.pageSize
                                },
                                setValue: handlePaginateChange
                            }}
                            loading={loading}
                            searchValue={filterKycParams.searchValue || ""}
                            setSearchValue={handleSearchChange}
                        />
                    </TabItem>
                </div>
            </Tab>
        </AppLayout>
    );
};

export default Risk;
