import Button from "@ui/Button.tsx";
import Checkbox from "@ui/Checkbox.tsx";
import Modal from "@ui/modal";
import { useCallback } from "react";

import { cn } from "../../libs/style.ts";
import {
    AccountStatusType,
    KycStatusType,
    KycType,
    ModalPropsType
} from "../../types";

export interface KycSelectedType {
    transfer: {
        value: boolean;
        disable: boolean;
    };
    card: {
        value: boolean;
        disable: boolean;
    };
}

interface Props extends ModalPropsType {
    title: string;
    description: string;
    loading?: boolean;
    onConfirm?: () => void;
    confirmAction: KycStatusType | AccountStatusType | null;
    kycSelected: KycSelectedType;
    setKycSelected: (value: KycSelectedType) => void;
    kyc?: KycType;
    buttonText?: {
        cancel?: string;
        confirm?: string;
    };
}

const ConfirmModal = (props: Props) => {
    const {
        isOpen,
        closeModal,
        title,
        description,
        buttonText = { cancel: "Annuler", confirm: "Confirmer" },
        onConfirm = () => {},
        kycSelected,
        setKycSelected,
        confirmAction,
        kyc,
        loading
    } = props;

    const handleClose = useCallback(() => {
        !loading && closeModal();
    }, [closeModal, loading]);

    return (
        <Modal isOpen={isOpen} closeModal={handleClose} className="w-[500px]">
            <div className="p-6">
                <h3 className="text-center text-3xl text-gray-700 mb-3">
                    {title}
                </h3>

                <p
                    className={cn({
                        "text-center text-gray-500 mb-4": true,
                        "mb-8": !(
                            AccountStatusType.suspended !== confirmAction &&
                            AccountStatusType.actif !== confirmAction
                        )
                    })}
                >
                    {description}
                </p>

                {AccountStatusType.suspended !== confirmAction &&
                    AccountStatusType.actif !== confirmAction && (
                        <div className="flex justify-center space-x-10 w-2/3 mx-auto mb-8">
                            {!(
                                !!kyc?.transfer_kyc_validated_at &&
                                confirmAction === KycStatusType.rejected
                            ) && (
                                <div className="flex items-center space-x-4">
                                    <Checkbox
                                        checked={kycSelected.transfer.value}
                                        disabled={kycSelected.transfer.disable}
                                        onChange={e =>
                                            setKycSelected({
                                                ...kycSelected,
                                                transfer: {
                                                    ...kycSelected.transfer,
                                                    value: e.target.checked
                                                }
                                            })
                                        }
                                    >
                                        Transfert
                                    </Checkbox>
                                </div>
                            )}

                            {!(
                                !!kyc?.card_kyc_validated_at &&
                                confirmAction === KycStatusType.rejected
                            ) && (
                                <div className="flex items-center space-x-4">
                                    <Checkbox
                                        checked={kycSelected.card.value}
                                        disabled={kycSelected.card.disable}
                                        onChange={e =>
                                            setKycSelected({
                                                ...kycSelected,
                                                card: {
                                                    ...kycSelected.card,
                                                    value: e.target.checked
                                                }
                                            })
                                        }
                                    >
                                        Carte
                                    </Checkbox>
                                </div>
                            )}
                        </div>
                    )}

                <div className="flex items-center justify-center space-x-4">
                    <Button variant="gray" withAuto onClick={handleClose}>
                        {buttonText?.cancel || "Annuler"}
                    </Button>

                    <Button
                        withAuto
                        onClick={onConfirm}
                        loading={loading}
                        disabled={
                            AccountStatusType.suspended !== confirmAction &&
                            AccountStatusType.actif !== confirmAction &&
                            !(
                                kycSelected.transfer.value ||
                                kycSelected.card.value
                            )
                        }
                    >
                        {buttonText?.confirm || "Confirmer"}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
