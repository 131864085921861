import HiSquareRefreshIcon from "@icon/HiSquareRefreshIcon.tsx";
import TbDownloadIcon from "@icon/TbDownloadIcon.tsx";
import { createColumnHelper } from "@tanstack/table-core";
import Datatable from "@ui/datatable";
import Tooltip from "@ui/Tooltip.tsx";
import { useCallback, useEffect, useMemo, useState } from "react";

import HeaderAccountTransactionFile from "../../components/HeaderAccountTransactionFile.tsx";
import { AppLayout } from "../../components/layouts";
import {
    DATATABLE,
    ENDPOINTS,
    PERMISSIONS,
    RESPONSE_STATUS,
    SECOND_DATE_TIME_FR_FORMAT
} from "../../constants";
import { updateAxiosBaseUrl, userHavePermissions } from "../../helpers";
import { formatDate } from "../../libs/date.ts";
import useToast from "../../libs/useToast.tsx";
import { ApiService } from "../../types";

interface AccountFileType {
    id: number;
    name: string;
    created_at: string;
    period: string;
    path: string;
}

interface AccountFilesDataStateType {
    currentPageData: AccountFileType[];
    total: number;
}

const columnHelper = createColumnHelper<AccountFileType>();

const Accounts = () => {
    const [loading, setLoading] = useState(false);
    const [accountFilesData, setAccountFilesData] =
        useState<AccountFilesDataStateType>({
            currentPageData: [],
            total: 0
        });
    const [date, setDate] = useState("");
    const [{ pageIndex, pageSize }, setPaginate] = useState({
        pageIndex: DATATABLE.PAGE_INDEX,
        pageSize: DATATABLE.PAGE_SIZE
    });
    const [generation, setGeneration] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [search, setSearch] = useState("");

    const {
        customSuccess: customSuccessToast,
        customError: customErrorToast,
        loading: loadingToast
    } = useToast();

    const refreshAccountFiles = useCallback(() => {
        setLoading(true);
        updateAxiosBaseUrl(ApiService.moc);
        window.axios
            .get(ENDPOINTS.FILE_ACCOUNT, {
                params: { page: pageIndex + 1, limit: pageSize, name: search }
            })
            .then(response => {
                const data = response.data.data;
                setAccountFilesData({
                    currentPageData: data.files,
                    total: data.total
                });
            })
            .catch(() => {
                setAccountFilesData({
                    currentPageData: [],
                    total: 0
                });
            })
            .finally(() => setLoading(false));
    }, [pageIndex, pageSize, search]);

    useEffect(() => {
        refreshAccountFiles();
    }, [refreshAccountFiles]);

    const handleGenerateFile = useCallback(
        (date: string, toastLoading = false) => {
            let toastId = "";
            if (!toastLoading) {
                setGeneration(true);
            } else {
                toastId = loadingToast("Chargement...");
            }

            updateAxiosBaseUrl(ApiService.moc);
            window.axios
                .post(ENDPOINTS.FILE_ACCOUNT_GENERATE, {
                    date
                })
                .then(() => {
                    Object.keys(errors).length && setErrors({});
                    refreshAccountFiles();
                    customSuccessToast(
                        { message: "Succès de la génération du fichier" },
                        {
                            id: toastId
                        }
                    );
                })
                .catch(errorResponse => {
                    Object.keys(errors).length && setErrors({});
                    customErrorToast(
                        { message: "Echec de la génération du fichier" },
                        { id: toastId }
                    );
                    const status = errorResponse?.response?.status;
                    if (status === RESPONSE_STATUS.UNPROCESSABLE_ENTITY) {
                        setErrors(errorResponse?.response?.data.errors);
                    }
                })
                .finally(() => {
                    if (!toastLoading) {
                        setGeneration(false);
                    }
                });
        },
        [
            customErrorToast,
            customSuccessToast,
            errors,
            loadingToast,
            refreshAccountFiles
        ]
    );

    const columns = useMemo(() => {
        return [
            columnHelper.accessor(row => row.name, {
                id: "name",
                header: () => <>Nom du fichier</>,
                cell: info => <>{info.getValue()}</>,
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.created_at, {
                id: "created_at",
                header: () => <>Date du fichier</>,
                cell: info => (
                    <>
                        {formatDate(
                            info.getValue(),
                            SECOND_DATE_TIME_FR_FORMAT
                        )}
                    </>
                ),
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.id, {
                id: "id",
                header: () => <>Action</>,
                cell: info => (
                    <div className="flex items-center space-x-4">
                        <Tooltip content="Télécharger la transaction">
                            <a
                                href={info.row.original.path}
                                download
                                className="focus:outline-none text-gray-400 hover:text-gray-500"
                            >
                                <TbDownloadIcon />
                            </a>
                        </Tooltip>

                        {userHavePermissions([
                            PERMISSIONS.GENERATE_ACCOUNT_FILE
                        ]) && (
                            <Tooltip content="Relancer">
                                <button
                                    onClick={() =>
                                        handleGenerateFile(
                                            info.row.original.period,
                                            true
                                        )
                                    }
                                    className="focus:outline-none text-gray-400 hover:text-gray-500"
                                >
                                    <HiSquareRefreshIcon />
                                </button>
                            </Tooltip>
                        )}
                    </div>
                ),
                footer: info => info.column.id
            })
        ];
    }, [handleGenerateFile]);

    return (
        <AppLayout className="space-y-5">
            {userHavePermissions([PERMISSIONS.GENERATE_ACCOUNT_FILE]) && (
                <HeaderAccountTransactionFile
                    date={date}
                    setDate={setDate}
                    errors={errors}
                    loading={generation}
                    onSubmit={() => handleGenerateFile(date)}
                />
            )}

            <div className="bg-white border rounded-md px-4 py-5">
                <Datatable
                    title="Fichiers suivi des comptes"
                    columns={columns}
                    data={accountFilesData.currentPageData}
                    allDataTotal={accountFilesData.total}
                    manualFetchData
                    manualPaginateState={{
                        value: { pageIndex, pageSize },
                        setValue: setPaginate
                    }}
                    loading={loading}
                    searchValue={search}
                    setSearchValue={setSearch}
                />
            </div>
        </AppLayout>
    );
};

export default Accounts;
