import TbRefreshIcon from "@icon/TbRefreshIcon.tsx";
import Button from "@ui/Button.tsx";
import { useCallback, useEffect, useMemo, useState } from "react";

import DownloadTransferDropdown from "../../components/DownloadTransferDropdown.tsx";
import { AppLayout } from "../../components/layouts";
import TransferDetail from "../../components/TransferDetail.tsx";
import { ENDPOINTS, PERMISSIONS, RESPONSE_STATUS } from "../../constants";
import { updateAxiosBaseUrl, userHavePermissions } from "../../helpers";
import useToast from "../../libs/useToast.tsx";
import { ApiService, PAY_IN_PAY_OUT_STATUS, TransferType } from "../../types";

import EmptyFilter from "./components/EmptyFilter.tsx";
import FilterLoading from "./components/FilterLoading.tsx";
import FilterTransferParameter, {
    FilterValueProps
} from "./components/FilterTransferParameter.tsx";

const SearchTransfer = () => {
    const [transfer, setTransfer] = useState<TransferType | null>(null);
    const [loadingRefund, setLoadingRefund] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState<FilterValueProps>({
        reference: "",
        identifier: "",
        paymentMethods: null
    });

    const { customSuccess, customError } = useToast();

    useEffect(() => {
        if (
            (filter.reference || filter.identifier) &&
            filter.paymentMethods?.id
        ) {
            setLoading(true);
            updateAxiosBaseUrl(ApiService.moc);
            window.axios
                .get(ENDPOINTS.TRANSFERS, {
                    params: {
                        identifier: filter.identifier,
                        ref_pay_in_partner: filter.reference || "",
                        pay_in_channel_id: filter.paymentMethods?.id || ""
                    }
                })
                .then(response => {
                    const data = response.data.data;

                    if (
                        data.transfers?.length === 1 &&
                        Object.keys(data.transfers[0]).length
                    ) {
                        setTransfer(data.transfers[0]);
                    } else {
                        setTransfer(null);
                    }
                })
                .finally(() => setLoading(false));
        }
    }, [filter]);

    const toRefund = useMemo(
        () =>
            transfer?.status_pay_in === PAY_IN_PAY_OUT_STATUS.SUCCESS &&
            transfer?.status_pay_out === PAY_IN_PAY_OUT_STATUS.FAILED,
        [transfer?.status_pay_in, transfer?.status_pay_out]
    );

    const requestToRefund = useCallback(() => {
        if (transfer?.id) {
            setLoadingRefund(true);
            window.axios
                .put(
                    ENDPOINTS.REQUEST_TO_REFUND_TRANSFER.replace(
                        ":id",
                        transfer.id.toString()
                    )
                )
                .then(response => {
                    const data = response.data.data;
                    if (Object.keys(data).length) {
                        setTransfer(data);
                    } else {
                        setTransfer(null);
                    }
                    customSuccess({
                        message: "Succès de la demande de remboursement"
                    });
                })
                .catch(error => {
                    const status = error.response?.status;
                    const message = error.response?.data?.message || "";

                    if (status === RESPONSE_STATUS.CONFLICT) {
                        customError({ message }, { duration: 10000 });
                    } else {
                        customError({
                            message: "Echec de la demande de remboursement"
                        });
                    }
                })
                .finally(() => setLoadingRefund(false));
        }
    }, [customError, customSuccess, transfer?.id]);

    return (
        <AppLayout className="space-y-5">
            <FilterTransferParameter filter={filter} setFilter={setFilter} />

            <div className="bg-white border rounded-md px-4 py-5">
                <h4 className="text-xl font-medium">Transaction</h4>

                {loading ? (
                    <FilterLoading />
                ) : (
                    <>
                        {!transfer && <EmptyFilter />}

                        {transfer && (
                            <TransferDetail
                                transfer={transfer}
                                containerClassName="px-0"
                            />
                        )}
                    </>
                )}

                {!!transfer && (
                    <div className="mt-4 p-6 flex items-center justify-end space-x-4">
                        {toRefund &&
                            userHavePermissions([
                                PERMISSIONS.ASK_TO_REFUND_TRANSFER
                            ]) && (
                                <Button
                                    variant="gray"
                                    withAuto
                                    icon={<TbRefreshIcon />}
                                    onClick={requestToRefund}
                                    loading={loadingRefund}
                                >
                                    Demander remboursement
                                </Button>
                            )}

                        <DownloadTransferDropdown
                            transfer={transfer}
                            typeButton="button"
                        />
                    </div>
                )}
            </div>
        </AppLayout>
    );
};

export default SearchTransfer;
