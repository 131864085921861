import HeEyeIcon from "@icon/HeEyeIcon.tsx";
import TbRefreshIcon from "@icon/TbRefreshIcon.tsx";
import { createColumnHelper } from "@tanstack/table-core";
import Button from "@ui/Button.tsx";
import Checkbox from "@ui/Checkbox.tsx";
import ConfirmationModal from "@ui/ConfirmationModal.tsx";
import Datatable from "@ui/datatable";
import { useModal } from "@ui/modal";
import Tooltip from "@ui/Tooltip.tsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";

import DownloadTransferDropdown from "../../components/DownloadTransferDropdown.tsx";
import { FormatStatusTransfer } from "../../components/Formater.tsx";
import { AppLayout } from "../../components/layouts";
import {
    DATATABLE,
    ENDPOINTS,
    PAGES,
    PERMISSIONS,
    SECOND_DATE_TIME_FR_FORMAT
} from "../../constants";
import {
    downloadFile,
    formatAmount,
    updateAxiosBaseUrl,
    userHavePermissions
} from "../../helpers";
import useCheckboxDatatable from "../../hooks/useCheckboxDatatable.ts";
import useCountries from "../../hooks/useCountries.tsx";
import useManageTransfer from "../../hooks/useManageTransfer.ts";
import { formatDate } from "../../libs/date.ts";
import useToast from "../../libs/useToast.tsx";
import { ApiService, TransferType } from "../../types";

import FilterTransfersParams, {
    FilterValueType
} from "./components/FilterTransfersParams.tsx";

interface TransferDataStateType {
    currentPageData: TransferType[];
    total: number;
}

export interface FilterParamsType {
    page: number;
    limit: number;
    date: string;
    status: string;
    identifier: string;
    pay_in_channels: number[];
    pay_out_channels: number[];
    account_id: string;
    ref_pay_in_partner: string;
    ref_pay_out_partner: string;
}

const INITIAL_FILTER = {
    date: "",
    status: null,
    idTransfer: "",
    paymentMethods: {
        pay_in: [],
        pay_out: []
    },
    service: {
        label: "Transfert",
        value: "transfer"
    },
    idAccount: "",
    payInPartner: "",
    payOutPartner: ""
};

const columnHelper = createColumnHelper<TransferType>();

const Transfers = () => {
    const [transferData, setTransferData] = useState<TransferDataStateType>({
        currentPageData: [],
        total: 0
    });
    const [{ pageIndex, pageSize }, setPaginate] = useState({
        pageIndex: DATATABLE.PAGE_INDEX,
        pageSize: DATATABLE.PAGE_SIZE
    });
    const [filterLoading, setFilterLoading] = useState(false);
    const [filter, setFilter] = useState<FilterValueType>(INITIAL_FILTER);
    const [tempFilter, setTempFilter] =
        useState<FilterValueType>(INITIAL_FILTER);
    const [downloadLoading, setDownloadLoading] = useState(false);

    const {
        checkData,
        checkAll,
        handleCheckAll,
        handleCheckItem,
        printItemCheckboxStatus,
        resetCheckData
    } = useCheckboxDatatable({ totalData: transferData.total });
    const { canRefundTransfer, handleRefund, refundLoading } =
        useManageTransfer();
    const [currentTransfer, setCurrentTransfer] = useState<TransferType | null>(
        null
    );
    const { isOpen, closeModal, openModal } = useModal();
    const { customSuccess: customSuccessToast, customError: customErrorToast } =
        useToast();
    const { getCountryByCountryId } = useCountries();

    const applyFilter = useCallback((filter: FilterParamsType) => {
        setFilterLoading(true);
        updateAxiosBaseUrl(ApiService.moc);
        window.axios
            .get(ENDPOINTS.TRANSFERS, {
                params: { ...filter }
            })
            .then(response => {
                const data = response.data.data;
                if (Array.isArray(data.transfers) && data.total) {
                    setTransferData({
                        currentPageData: data.transfers,
                        total: data.total
                    });
                }
            })
            .catch(() => {
                setTransferData({
                    currentPageData: [],
                    total: 0
                });
            })
            .finally(() => setFilterLoading(false));
    }, []);

    const handleFilter = useCallback(() => {
        setPaginate({ pageIndex: DATATABLE.PAGE_INDEX, pageSize });
        resetCheckData();
        setFilter(tempFilter);
    }, [pageSize, resetCheckData, tempFilter]);

    const onApplyFilter = useCallback(() => {
        applyFilter({
            page: pageIndex + 1,
            limit: pageSize,
            date: filter.date,
            status: filter.status ? filter.status.value : "",
            identifier: filter.idTransfer,
            pay_in_channels: filter.paymentMethods.pay_in,
            pay_out_channels: filter.paymentMethods.pay_out,
            account_id: filter.idAccount,
            ref_pay_in_partner: filter.payInPartner,
            ref_pay_out_partner: filter.payOutPartner
        });
    }, [
        applyFilter,
        filter.date,
        filter.idAccount,
        filter.idTransfer,
        filter.payInPartner,
        filter.payOutPartner,
        filter.paymentMethods.pay_in,
        filter.paymentMethods.pay_out,
        filter.status,
        pageIndex,
        pageSize
    ]);

    useEffect(() => {
        onApplyFilter();
    }, [onApplyFilter]);

    const downloadTransfers = useCallback(() => {
        const sendData = {
            ...checkData,
            filter: {
                created_at: filter.date,
                status: filter.status ? filter.status.value : "",
                identifier: filter.idTransfer,
                pay_in_channels: filter.paymentMethods.pay_in,
                pay_out_channels: filter.paymentMethods.pay_out,
                account_id: filter.idAccount,
                ref_pay_in_partner: filter.payInPartner,
                ref_pay_out_partner: filter.payOutPartner
            }
        };
        setDownloadLoading(true);
        updateAxiosBaseUrl(ApiService.moc);
        window.axios
            .post(ENDPOINTS.DOWNLOAD_TRANSFER, sendData)
            .then(response => {
                const url = response.data.data.url;
                const message = response.data.message || "";
                if (url && typeof url === "string") {
                    downloadFile(url);
                    setTimeout(() => {
                        customSuccessToast({
                            message: "Succès du téléchargement"
                        });
                    }, 1000);
                } else {
                    customSuccessToast({
                        message
                    });
                }
            })
            .catch(() => {
                customErrorToast({ message: "Echec du téléchargement" });
            })
            .finally(() => setDownloadLoading(false));
    }, [
        checkData,
        customErrorToast,
        customSuccessToast,
        filter.date,
        filter.idAccount,
        filter.idTransfer,
        filter.payInPartner,
        filter.payOutPartner,
        filter.paymentMethods.pay_in,
        filter.paymentMethods.pay_out,
        filter.status
    ]);

    const columns = useMemo(() => {
        return [
            columnHelper.accessor(row => row.created_at, {
                id: "created_at",
                header: () => (
                    <div className="flex items-center space-x-3">
                        <Checkbox
                            id="check-all"
                            checked={checkAll}
                            onChange={handleCheckAll}
                        />
                        <span>Date</span>
                    </div>
                ),
                cell: info => {
                    return (
                        <div className="flex items-center space-x-3">
                            <Checkbox
                                checked={printItemCheckboxStatus(
                                    info.row.original.id
                                )}
                                onChange={e =>
                                    handleCheckItem(e, info.row.original.id)
                                }
                            />
                            <span>
                                {formatDate(
                                    info.getValue() || "",
                                    SECOND_DATE_TIME_FR_FORMAT.replaceAll(
                                        "-",
                                        " "
                                    )
                                )}
                            </span>
                        </div>
                    );
                },
                footer: info => info.column.id
            }),
            columnHelper.accessor(() => "Transfert", {
                id: "serviceable_type",
                header: () => <>Service</>,
                cell: info => <div>{info.getValue()}</div>,
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.amount, {
                id: "amount",
                header: () => <>Montant</>,
                cell: info => <div>{formatAmount(info.getValue())} CFA</div>,
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.status, {
                id: "status",
                header: () => <>Status</>,
                cell: info => {
                    return (
                        <FormatStatusTransfer status={info.getValue() || ""} />
                    );
                },
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.pay_in_channel, {
                id: "pay_in_channel",
                header: () => <>MP Emission</>,
                cell: info => {
                    const payInChannel = info.getValue();

                    const countryId = payInChannel?.country_id;
                    const country = countryId
                        ? getCountryByCountryId(
                              parseInt(countryId.toString())
                          ) || null
                        : null;

                    return (
                        <div>
                            {payInChannel?.name || "---"}{" "}
                            {payInChannel?.country_id && (
                                <>({country?.name || "---"})</>
                            )}
                        </div>
                    );
                },
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.pay_out_channel, {
                id: "pay_out_channel",
                header: () => <>MP Reception</>,
                cell: info => {
                    const payOutChannel = info.getValue();

                    const countryId = payOutChannel?.country_id;
                    const country = countryId
                        ? getCountryByCountryId(
                              parseInt(countryId.toString())
                          ) || null
                        : null;

                    return (
                        <div>
                            {payOutChannel?.name || "---"} (
                            {country?.name || "---"})
                        </div>
                    );
                },
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.identifier, {
                id: "identifier",
                header: () => <div>Action</div>,
                cell: info => {
                    const transfer = info.row.original;

                    return (
                        <div className="flex items-center space-x-4">
                            <Tooltip content="Détail">
                                <NavLink
                                    to={PAGES.TRANSFER_DETAILS.replace(
                                        ":id",
                                        info.getValue()
                                    )}
                                    className="focus:outline-none text-gray-400 hover:text-gray-500"
                                >
                                    <HeEyeIcon />
                                </NavLink>
                            </Tooltip>

                            {canRefundTransfer(info.row.original) &&
                                userHavePermissions([
                                    PERMISSIONS.REFUND_TRANSFER
                                ]) && (
                                    <Tooltip content="Rembourser">
                                        <button
                                            onClick={() => {
                                                openModal();
                                                setCurrentTransfer(
                                                    info.row.original
                                                );
                                            }}
                                            className="focus:outline-none text-gray-400 hover:text-gray-500"
                                        >
                                            <TbRefreshIcon />
                                        </button>
                                    </Tooltip>
                                )}

                            <DownloadTransferDropdown transfer={transfer} />
                        </div>
                    );
                },
                footer: info => info.column.id
            })
        ];
    }, [
        canRefundTransfer,
        checkAll,
        getCountryByCountryId,
        handleCheckAll,
        handleCheckItem,
        openModal,
        printItemCheckboxStatus
    ]);

    return (
        <AppLayout className="space-y-5">
            <FilterTransfersParams
                onFilter={handleFilter}
                filter={tempFilter}
                setFilter={setTempFilter}
                loading={filterLoading}
            />

            <div className="bg-white border rounded-md px-4 py-5">
                <Datatable
                    title={
                        <>
                            <span>Liste des transaction</span>

                            {userHavePermissions([
                                PERMISSIONS.DOWNLOAD_TRANSFER
                            ]) && (
                                <Button
                                    withAuto
                                    size="sm"
                                    className="px-3"
                                    onClick={downloadTransfers}
                                    loading={downloadLoading}
                                    disabled={
                                        !checkData.uncheck.length &&
                                        !checkData.check.length &&
                                        !checkAll
                                    }
                                >
                                    Télécharger
                                </Button>
                            )}
                        </>
                    }
                    columns={columns}
                    data={transferData.currentPageData}
                    allDataTotal={transferData.total}
                    manualFetchData
                    manualPaginateState={{
                        value: { pageIndex, pageSize },
                        setValue: setPaginate
                    }}
                    loading={filterLoading}
                    isSearchable={false}
                />
            </div>

            {currentTransfer && (
                <ConfirmationModal
                    title="Êtes-vous sûr"
                    description="Vous voulez rembourser cette transaction"
                    isOpen={isOpen}
                    loading={refundLoading}
                    onConfirm={() => {
                        handleRefund(currentTransfer?.id.toString(), () => {
                            onApplyFilter();
                        });
                    }}
                    closeModal={() => {
                        closeModal();
                        setCurrentTransfer(null);
                    }}
                />
            )}
        </AppLayout>
    );
};

export default Transfers;
